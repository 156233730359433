import {
	isValid,
	isBefore,
	add,
	differenceInMilliseconds,
	isToday,
	Locale,
	parseISO,
	isSameDay,
	isWithinInterval,
	isAfter,
} from 'date-fns'
import * as Locales from 'date-fns/locale'
import { format, utcToZonedTime, toDate, formatInTimeZone } from 'date-fns-tz'
import { getI18n } from 'react-i18next'

import { DateIsoString, Languages } from '../model/model'
import { Optional } from '../utils/utilityTypes'

import { getDateFnsLocale } from './localization'

export const TWENTY_MINUTES = 1000 * 60 * 20
export const FIVE_MINUTES = 1000 * 60 * 5
export const ONE_MINUTE = 1000 * 60 * 1
export const TWO_MINUTES = 1000 * 60 * 2
export const THREE_MINUTES = 1000 * 60 * 3
export const FIVE_SECONDS = 1000 * 5
export const TEN_SECONDS = 1000 * 10
export const FIFTEEN_SECONDS = 1000 * 15
export const HALF_HOUR = 1000 * 60 * 30

export const getYear = (time: string | number | Date): string =>
	new Date(time).getFullYear().toString()

export const getMonth = (time: string | number | Date): string => {
	const date = new Date(time)
	const month = date.getMonth() + 1
	return month < 10 ? `0${month}` : month.toString()
}

export const getDay = (time: string | number | Date): string => {
	const date = new Date(time)
	const day = date.getDate()
	return day < 10 ? `0${day}` : day.toString()
}

export const getLocalizedTime = (
	time: string | number | Date,
	locale = 'en-US',
	options?: Intl.DateTimeFormatOptions,
) => {
	const date = new Date(time)
	var formatter = new Intl.DateTimeFormat(locale, {
		hour: 'numeric',
		minute: 'numeric',
		...(options || {}),
	})

	return formatter.format(date)
}

export const getLocalizedDateShort = (
	time: Date | string | number,
	locale = 'en-US',
	options?: Intl.DateTimeFormatOptions,
) => {
	const date = new Date(time)
	var formatter = new Intl.DateTimeFormat(locale, {
		month: 'short',
		day: 'numeric',
		...(options || {}),
	})

	return formatter.format(date)
}

export const getLocalizedDate = (
	time: Date | string | number,
	locale = 'en-US',
	options?: Intl.DateTimeFormatOptions,
) => {
	const date = new Date(time)

	if (options && options.timeZone !== undefined) {
		try {
			new Intl.DateTimeFormat(undefined, { timeZone: options.timeZone })
		} catch (e) {
			console.error(
				`Invalid time zone specified at getLocalizedDate in src/libs/time.ts: '${options.timeZone}'`,
			)
			delete options.timeZone
		}
	}

	const formatter = new Intl.DateTimeFormat(locale, {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		...options,
	})

	return formatter.format(date)
}

export const getLocalizedDateWithDefinedLocale = (
	time: Date | string | number,
	options?: Intl.DateTimeFormatOptions,
	locale?: string,
) => {
	const lang = locale || getI18n()?.resolvedLanguage || Languages.ENGLISH

	return getLocalizedDate(time, lang, options)
}

export const getShortLocalizedDateWithDefinedLocale = (
	time: Date | string | number,
	options?: Intl.DateTimeFormatOptions,
	locale?: string,
) => {
	const lang = locale || getI18n()?.resolvedLanguage || Languages.ENGLISH

	return getLocalizedDateShort(time, lang, options)
}

export const convertDateForQueryString = (input: Date | string) => {
	const date = typeof input === 'string' ? new Date(input) : input
	const day = getDay(date)
	const month = getMonth(date)
	const year = getYear(date)

	return `${year}-${month}-${day}`
}

const calcDateTimeOffset = (dateIn: Date) => {
	const date = new Date(dateIn)
	return date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
}

export const convertDateForBody = (dateIn: Date) => {
	const date = calcDateTimeOffset(dateIn)
	const day = getDay(date)
	const month = getMonth(date)
	const year = getYear(date)

	return `${year}-${month}-${day}T00:00:00Z`
}

export const convertDateForOtt = (
	dateIn: Date,
	hour: number,
	minutes: number,
) => {
	const date = calcDateTimeOffset(dateIn)
	const day = getDay(date)
	const month = getMonth(date)
	const year = getYear(date)
	const formatHour = hour < 10 ? `0${hour}` : hour
	const formatMinutes = hour < 10 ? `0${minutes}` : minutes

	return `${year}-${month}-${day}T${formatHour}:${formatMinutes}:00+02:00`
}

export const formatDate = (
	time: Date | string | number,
	dateFormat: string,
	localeCode: string,
): string => {
	if (!checkIsValidDate(time)) {
		return ''
	}

	const date = new Date(time)
	const locale = pickDateFnsLocale(localeCode)
	return format(date, dateFormat, { locale })
}

export const formatDateInTimeZone = (
	date: Date | string | number,
	dateFormat: string,
	timezone: string,
): string => {
	if (!checkIsValidDate(date) || !isValidTimeZone(timezone)) {
		console.error(`
			Date: '${date}',
			Timezone: '${timezone}'
		`)
		return ''
	}
	return formatInTimeZone(date, timezone, dateFormat)
}

export const isDayPast = (date: Date | string | number) => {
	const d = new Date(date)
	const day = d.getDate()
	const month = d.getMonth()
	const year = d.getFullYear()
	const today = new Date()
	const todayDay = today.getDate()
	const todayMonth = today.getMonth()
	const todayYear = today.getFullYear()
	return todayYear >= year && todayMonth >= month && todayDay > day
}

export const isTodayZoned = (
	date: Date | DateIsoString | undefined,
	timezone: string,
) => {
	if (!date) {
		return false
	}

	const zonedTime = utcToZonedTime(date, timezone)
	const $isToday = isToday(zonedTime)
	return $isToday
}

export const getIsTheSameDay = (date1: string | Date, date2: string | Date) => {
	return isSameDay(new Date(date1), new Date(date2))
}

export const getIsDateWithinDateRange = (
	date: string | Date,
	[startDate, endDate]: (string | Date | null | undefined)[],
) => {
	if (!startDate) {
		return false
	}

	if (!endDate) {
		return getIsTheSameDay(date, startDate)
	}

	const isStartDateAfterEndDate = isAfter(
		new Date(startDate),
		new Date(endDate),
	)

	if (isStartDateAfterEndDate) {
		return false
	}

	return isWithinInterval(new Date(date), {
		start: new Date(startDate),
		end: add(new Date(endDate), { days: 1 }),
	})
}

export const convertDateToMs = (date: Date) => new Date(date).getTime()

export const formatSecondsForTimer = (seconds: number) => {
	const mm = Math.floor(seconds / 60)
	const ss = Math.floor(seconds - mm * 60)
	return `${mm > 9 || mm < -9 ? mm : `${mm < 0 ? '-' : ''}0${Math.abs(mm)}`}:${
		ss > 9 ? ss : `0${ss}`
	}`
}

export const convertSecondsToMinutes = (seconds: number) =>
	Math.round(seconds / 60)

export const convertMillisecondsToMinutes = (milliseconds: number) =>
	Math.round(milliseconds / (1000 * 60))

export const isPastByMoreThanMinutes = (
	date: string,
	timezone: string,
	minutes: number,
) => {
	const now = utcToZonedTime(new Date(), timezone)
	const target = add(utcToZonedTime(toDate(date), timezone), {
		minutes,
	})
	const res = isBefore(now, target)
	return res
}

/**
 * @deprecated
 * this function can result in errors such as https://abstractsrl.atlassian.net/browse/TEL-3590
 * to parse appointments dates and get a Date object in users's local time use parseAppointmentDate function
 */
export const getUTCTime = (date: Date | string | number) => {
	const d = new Date(date)
	const timeZoneOffsetInMilliseconds = d.getTimezoneOffset() * 60 * 1000
	return d.getTime() + timeZoneOffsetInMilliseconds
}

export const getLocalTime = () => {
	const d = new Date()
	const ts = d.getTime() - d.getTimezoneOffset() * 60 * 1000
	return new Date(ts).toISOString()
}

export const isPastByMoreThanNDays = (
	date: Date | string | number,
	days: number,
) => {
	const now = new Date()
	const delta = now.getTime() - getUTCTime(date)
	return delta > days * 1000 * 60 * 60 * 24
}

export const distanceFromDate = (
	timezone: string | undefined,
	d: Date | DateIsoString,
	daysFromDate?: number,
) => {
	if (!timezone) {
		return null
	}

	const date = new Date(d)
	const days = daysFromDate || daysFromDate === 0 ? daysFromDate : 2
	const endDate = new Date(date.setDate(date.getDate() + days))

	const format = 'yyyy-MM-dd HH:mm:ssXXX'

	const currentTimeTZ = formatInTimeZone(new Date(), timezone, format)

	const timeToEndTZ = formatInTimeZone(endDate, timezone, format)

	const currentTimeTZDate = utcToZonedTime(currentTimeTZ, timezone)
	const timeToEndTZDate = utcToZonedTime(timeToEndTZ, timezone)

	const differenceInMS = differenceInMilliseconds(
		timeToEndTZDate,
		currentTimeTZDate,
	)

	const minutes = Math.floor((differenceInMS / (1000 * 60)) % 59) + 1
	const hours = Math.floor(differenceInMS / (1000 * 60 * 60))

	return hours < 0 ? null : `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`
}

export const padTo2Digits = (num: number) => {
	return num.toString().padStart(2, '0')
}

export const isValidDate = (d: Date | null) =>
	d instanceof Date && !isNaN(d.getTime())

export const inYear = (d: Date) => {
	const dateFrom = new Date()
	dateFrom.setFullYear(dateFrom.getUTCFullYear() - 1)
	dateFrom.setHours(0)
	dateFrom.setMinutes(0)
	dateFrom.setSeconds(0)
	dateFrom.setMilliseconds(0)
	return d.getTime() >= dateFrom.getTime()
}

export const checkIsDate = (dateString: string): boolean => {
	return isValid(parseISO(dateString))
}

export const formatDateStringToISOQuery = (dateString: string) => {
	return format(parseISO(dateString), 'yyyy-MM-dd')
}

export const buildDateQueryParam = (
	paramName: string,
	value: string,
	formatTemplate = 'yyyy-MM-dd',
	isFirstParam = false,
) => {
	const mayBeDate = new Date(value)
	return isValid(mayBeDate)
		? `${isFirstParam ? '?' : '&'}${paramName}=${format(
				mayBeDate,
				formatTemplate,
		  )}`
		: ''
}

export const formatISOstring = ({
	date,
	dateFormat,
	timeZone,
}: {
	date: string
	dateFormat: string
	timeZone: string
}) => {
	const locale = getDateFnsLocale()
	const dateFormatted = format(
		utcToZonedTime(toDate(date), timeZone),
		dateFormat,
		{ timeZone, locale },
	)
	return dateFormatted
}

export const isValidDateFormat = (
	formatStr: Optional<string>,
): formatStr is string => {
	if (!formatStr) {
		return false
	}
	let err = false
	try {
		format(new Date(), formatStr)
	} catch {
		err = true
	}
	return !err
}

/**
 * Takes a ISO locale code and returns a date-fns object representing a Locale,
 * fully equipped with everying date-fns needs from a locale.
 */
export const pickDateFnsLocale = (localeCode: string): Locale => {
	return (Locales as any)[localeCode.replace('-', '')]
}

/**
 * If string, then it must be in ISO string, otherwise and "Invalid Date" will be returned
 * @param date the date to parse
 * @returns the parsed date
 */
export const parseToDate = (
	date: Optional<string | Date | number>,
): Optional<Date> => {
	if (date == null) {
		return date
	}
	if (date instanceof Date) {
		return date
	}
	if (typeof date === 'number') {
		return new Date(date)
	}
	return parseISO(date)
}

export const convertSingleUnit = (
	unit: 'days' | 'weeks' | undefined,
	quantity: string | number | undefined,
) => {
	if (!unit) return ''

	if (quantity && +quantity === 1) {
		return unit.slice(0, -1)
	} else return unit
}

export const convertTimeIntoHours = (
	time: number | string | undefined,
	unit: string | undefined,
) => {
	if (!unit || !time) return

	if (unit === 'days') return +time * 24
	else if (unit === 'weeks') return +time * 24 * 7
}

export function addHours(date: Date, hours: number) {
	return new Date(date.getTime() + hours * 3600000) //60 * 60 * 1000
}

export const checkIsValidDate = (dateProps: any): boolean => {
	try {
		const date = new Date(dateProps)
		return !isNaN(date.getTime())
	} catch (error) {
		console.error(`
			Date: ${dateProps},
			Error: ${error}
		`)
		return false
	}
}

export const isValidTimeZone = (timezone: string): boolean => {
	try {
		const sampleDate = new Date()
		const formattedDate = utcToZonedTime(sampleDate, timezone)
		return checkIsValidDate(formattedDate)
	} catch (error) {
		return false
	}
}
